import React from "react"
import PropTypes from 'prop-types';
import {Row, Col, Container} from "react-bootstrap"
import get from 'lodash.get'
import Typography from "components/atoms/Typography"
import UnitCounter from "components/molecules/UnitCounter"

// Style
import './DataCards.scss'

function DataCards({ data }) {
  const themeClasses = get(data, 'theme.entity.classes')
  const id = get(data, 'id')
  const title = get(data, 'title')
  const items = get(data, 'items')

  return (
    <Container id={id} className={`data-cards ${themeClasses || 'bg-gray-400'} pb-5`} fluid="fluid">
      <Container>
        <Row>
          <Col xs={12} className="mt-4 mb-md-3">
            <Typography variant="h2" color="green-dark-100" cssClass="data-cards-title subtitle-bold-md">
              {title}
            </Typography>
          </Col>
        </Row>

        <Row>
          {Array.isArray(items) &&
            items
              .filter((i) => i.entity)
              .map(({entity}) => (
                <Col sm={6} md={4} className="mt-2" key={get(entity, 'uuid')}>
                  <UnitCounter
                    icon={get(entity, 'icon.entity.media.entity.fieldMediaFile.entity.url')}
                    id={get(entity, 'id')}
                    title={get(entity, 'title')}
                    text={get(entity, 'subtitle')}
                  />
                </Col>
              ))}
        </Row>
      </Container>
    </Container>
  )
}

DataCards.propTypes = {
  /**
   * Data from GraphQL Paragraph Object from Drupal
   */
  data: PropTypes.object,    
};

DataCards.defaultProps = {
  data: null,
};

export default DataCards