import React from "react"
import get from 'lodash.get'
import SVG from 'react-inlinesvg'

// Style
import "./UnitCounter.scss"

function UnitCounter(props) {
  const id = get(props, 'id')
  const title = get(props, 'title')
  const icon = get(props, 'icon')
  const text = get(props, 'text')

  return (
    <div id={id} className="unit-counter counter-info">
      {icon && (
        <SVG className="counter-info-icon" src={icon} preProcessor={(code) => code.replace(/filter=".*?"/g, '')} />
      )}
      <h3 className="counter-info-title">{title}</h3>
      <p className="counter-info-text">{text}</p>
    </div>
  );
}

export default UnitCounter;
